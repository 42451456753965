import { RefObject, useEffect } from 'react';

interface UsePopupWindowSubscriptionOptions {
  popupRef: RefObject<WindowProxy | null>;
  onMessage(message: unknown): void;
}

function usePopupWindowSubscription({ onMessage, popupRef }: UsePopupWindowSubscriptionOptions): void {
  useEffect(() => {
    const onMessageEvent = (event: MessageEvent) => {
      if (event.origin === new URL(window.location.href).origin && event.source === popupRef.current) {
        onMessage(event.data);
      }
    };

    window.addEventListener('message', onMessageEvent);

    return () => {
      window.removeEventListener('message', onMessageEvent);
    };
  }, [onMessage, popupRef]);
}

export { usePopupWindowSubscription };
