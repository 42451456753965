import { useState } from 'react';
import { PasswordResetForm } from './PasswordResetForm';
import { NewPasswordForm } from './NewPasswordForm';
import { AuthFormProps, AuthType } from '../../types';

const PasswordReset = ({ setAuthType }: AuthFormProps) => {
  const [email, setEmail] = useState<string | null>(null);
  const onDone = () => setAuthType(AuthType.Login);

  return !email ? <PasswordResetForm setEmail={setEmail} /> : <NewPasswordForm onDone={onDone} email={email} />;
};

export { PasswordReset };
