/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */
// @ts-nocheck

import * as Types from '../../../../lib/graphql.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { CurrentUserFragmentFragmentDoc } from '../../../../gql/CurrentUserFragment.document';
export type LoginMutationVariables = Types.Exact<{
  login: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginInfo', user?: { __typename?: 'User', id: string, alias: string, nickname?: string | null, nicknameChangeBlockedUntil?: number | string | null, aliasChangeBlockedUntil?: number | string | null, email: string, description?: string | null, moderationRequired: boolean, permissions: Array<Types.UserPermission>, thirdPartyApps: Array<Types.ThirdPartyApp>, avatar?: { __typename?: 'Avatar', originalUrl: string, cropSettings?: unknown | null } | null, contacts: { __typename?: 'Contacts', vk?: string | null, tg?: string | null, fb?: string | null } } | null } };


export const LoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Login"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"login"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"login"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"login"},"value":{"kind":"Variable","name":{"kind":"Name","value":"login"}}},{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CurrentUserFragment"}}]}}]}}]}},...CurrentUserFragmentFragmentDoc.definitions]} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;