import { Controller } from 'react-hook-form';
import { TextInput } from '../../../TextInput';
import { Button, ButtonVariant } from '../../../Button';
import { useLogin } from './useLogin';
import { Errors } from '../../../Errors';
import { AuthFormProps, AuthType } from '../../types';

const Login = ({ onClose, setAuthType }: AuthFormProps): JSX.Element => {
  const { submitButtonDisabled, errors, loading, onSubmit, control } = useLogin(onClose);

  return (
    <form onSubmit={onSubmit}>
      <Controller
        control={control}
        name="login"
        render={({ field }) => <TextInput className="border-b-0" {...field} placeholder="Почта или имя" />}
      />
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <TextInput {...field} placeholder="Пароль" type="password" autoComplete="current-password" />
        )}
      />
      <Errors errors={errors} />
      <Button className="mt-3" onClick={() => setAuthType(AuthType.PasswordReset)}>
        Я забыл пароль
      </Button>
      <Button
        className="mt-14 flex w-64 justify-start"
        type="submit"
        variant={ButtonVariant.Contained}
        disabled={submitButtonDisabled}
      >
        {loading ? 'Входим...' : 'Войти'}
      </Button>
    </form>
  );
};

export { Login };
