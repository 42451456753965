import { GOOGLE_AUTH, VK_AUTH, OK_AUTH } from '../../lib/auth';
import { AuthType } from './types';

export const AuthTypeItems = [
  {
    title: GOOGLE_AUTH.title,
    value: AuthType.Google,
    popupUrl: GOOGLE_AUTH.to,
  },
  {
    title: VK_AUTH.title,
    value: AuthType.Vk,
    popupUrl: VK_AUTH.to,
  },
  {
    title: OK_AUTH.title,
    value: AuthType.Ok,
    popupUrl: OK_AUTH.to,
  },
  {
    title: 'E-mail',
    value: AuthType.Login,
  },
  {
    title: 'Регистрация по e-mail',
    value: AuthType.Registration,
  },
];
