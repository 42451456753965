import { ComponentType } from 'react';
import { Login as LoginForm, Registration as RegistrationForm } from './components';
import { PasswordReset as PasswordResetForm } from './components/PasswordReset';
import { AuthFormProps, AuthType } from './types';

function getAuthForm(authType: AuthType): ComponentType<AuthFormProps> | null {
  switch (authType) {
    case AuthType.Login:
      return LoginForm;
    case AuthType.Registration:
      return RegistrationForm;
    case AuthType.PasswordReset:
      return PasswordResetForm;
    default:
      return null;
  }
}

export { getAuthForm };
