import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { LoginDocument } from './Login.document';
import { LoginFormState } from './types';
import { schema } from './validation';
import { ThemeDocument } from '../../../StaticHelmet/gql/Theme.document';
import { ignoreReject } from '../../../../lib/ignoreReject';

const useLogin = (onClose: () => void) => {
  // TODO: find out why it doesn't work automatically
  const [login, { loading }] = useMutation(LoginDocument);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<LoginFormState>({
    defaultValues: { login: '', password: '' },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((formData: LoginFormState) => {
    void ignoreReject(
      login({ variables: formData, refetchQueries: [ThemeDocument] }).then(() => {
        onClose();
      })
    );
  });

  const submitButtonDisabled = !isValid || loading;

  return { control, errors, onSubmit, setValue, submitButtonDisabled, loading };
};

export { useLogin };
