import { useCallback, useRef } from 'react';

function usePopupWindow() {
  const popupRef = useRef<WindowProxy | null>(null);

  const openPopup = useCallback((url: string) => {
    if (popupRef.current && !popupRef.current.closed) {
      popupRef.current.location.href = url;
      popupRef.current.focus();
    } else {
      popupRef.current = window.open(url, '', 'popup');
    }
  }, []);

  const closePopup = useCallback(() => {
    popupRef.current?.close();
    window.focus();
  }, []);

  return { openPopup, closePopup, popupRef };
}

export { usePopupWindow };
