import { tw } from '../../styles/tw';
import { Button, ButtonColor, ButtonVariant } from '../Button';
import { ClickableProps } from '../Clickable';

type ToggleButtonProps = ClickableProps<{ active?: boolean; variant?: ButtonVariant; color?: ButtonColor }>;

const ToggleButton = ({ active, className, ...props }: ToggleButtonProps): JSX.Element => (
  <Button variant={ButtonVariant.Outlined} className={tw(active && activeButton, className)} {...props} />
);

const activeButton = tw('bg-[var(--accent-color)] text-[var(--base-color)]');

export { ToggleButton };
export type { ToggleButtonProps };
