import { useState } from 'react';
import { RegistrationForm } from './RegistrationForm';
import { ConfirmationForm } from './ConfirmationForm';
import { User } from './types';
import { AuthFormProps, AuthType } from '../../types';

const Registration = ({ setAuthType }: AuthFormProps): JSX.Element => {
  const [user, setUser] = useState<User | null>(null);
  const onRegistrationComplete = (createdUser: User) => {
    setUser(createdUser);
  };
  const onConfirmationComplete = () => {
    setAuthType(AuthType.Login);
  };

  return !user ? (
    <RegistrationForm onComplete={onRegistrationComplete} />
  ) : (
    <ConfirmationForm user={user} onComplete={onConfirmationComplete} />
  );
};

export { Registration };
