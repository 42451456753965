import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { getDefined } from '@flame-frontend-utils/commons';
import { schema } from './validation';
import { RegistrationFormState, User } from '../types';
import { CreateUserDocument } from '../CreateUser.document';
import { ignoreReject } from '../../../../../lib/ignoreReject';

const useRegistration = (onComplete: (user: User) => void) => {
  const [createUser, { loading }] = useMutation(CreateUserDocument);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<RegistrationFormState>({
    defaultValues: { alias: '', nickname: '', email: '', password: '' },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (formData: RegistrationFormState) => {
    const response = await ignoreReject(createUser({ variables: { dto: { ...formData } } }));

    if (response) {
      const user = getDefined(response.data?.createUser);
      onComplete({ ...formData, id: user.id });
    }
  });

  const submitButtonDisabled = !isValid || loading;

  return { control, errors, onSubmit, setValue, submitButtonDisabled, loading };
};

export { useRegistration };
