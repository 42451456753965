import { Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Button, ButtonVariant } from '../../../../Button';
import { TextInput } from '../../../../TextInput';
import { useConfirmation } from './useConfirmation';
import { User } from '../types';
import { CreateUserDocument } from '../CreateUser.document';
import { Errors } from '../../../../Errors';
import { ignoreReject } from '../../../../../lib/ignoreReject';

interface ConfirmationFormProps {
  user: User;
  onComplete: () => void;
}

const ConfirmationForm = ({ user: { email, password, id }, onComplete }: ConfirmationFormProps) => {
  const { submitButtonDisabled, errors, onSubmit, control } = useConfirmation(id, onComplete);
  const [createUser, { loading }] = useMutation(CreateUserDocument);

  const onResend = () => {
    void ignoreReject(createUser({ variables: { dto: { email, password } } }));
  };

  return (
    <form onSubmit={onSubmit}>
      <p className="mb-3.5">Введите код из почты</p>
      <Controller
        control={control}
        name="code"
        render={({ field }) => <TextInput {...field} placeholder="Код" autoComplete="one-time-code" />}
      />
      <Errors errors={errors} />
      <Button className="mt-3" onClick={onResend} disabled={loading}>
        Отправить еще раз
      </Button>
      <Button
        className="mt-14 flex w-64 justify-start"
        type="submit"
        variant={ButtonVariant.Contained}
        disabled={submitButtonDisabled}
      >
        {loading ? 'Отправляем...' : 'Готово'}
      </Button>
    </form>
  );
};

export { ConfirmationForm };
