import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import * as yup from 'yup';
import { ConfirmUserRegistrationDocument } from '../ConfirmUserRegistration.document';
import { ConfirmationFormState } from '../types';
import { ignoreReject } from '../../../../../lib/ignoreReject';

const schema = yup
  .object({
    code: yup.string().required('Необходимо ввести код'),
  })
  .required();

const useConfirmation = (userId: string, onComplete: () => void) => {
  const [confirmUser, { loading }] = useMutation(ConfirmUserRegistrationDocument);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ConfirmationFormState>({
    defaultValues: { code: '' },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((formData: ConfirmationFormState) => {
    void ignoreReject(
      confirmUser({ variables: { id: userId, code: formData.code } }).then(() => {
        onComplete();
      })
    );
  });

  const submitButtonDisabled = !isValid || loading;

  return { control, errors, onSubmit, submitButtonDisabled, loading };
};

export { useConfirmation };
