import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PasswordResetState } from './types';
import { RequestUserPasswordResetDocument } from './RequestUserPasswordReset.document';
import { ignoreReject } from '../../../../../lib/ignoreReject';

const schema = yup
  .object({
    email: yup.string().email().required('Необходимо ввести email'),
  })
  .required();

export const usePasswordReset = (setEmail: (email: string) => void) => {
  const [resetPassword, { loading }] = useMutation(RequestUserPasswordResetDocument);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<PasswordResetState>({
    defaultValues: { email: '' },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const submitButtonDisabled = !isValid || loading;

  const onSubmit = handleSubmit(({ email }: PasswordResetState) => {
    void ignoreReject(
      resetPassword({ variables: { email } }).then(() => {
        setEmail(email);
      })
    );
  });

  return { control, errors, onSubmit, submitButtonDisabled, loading };
};
