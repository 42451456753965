import { Controller } from 'react-hook-form';
import { Button, ButtonVariant } from '../../../../Button';
import { useRegistration } from './useRegistration';
import { TextInput } from '../../../../TextInput';
import { User } from '../types';
import { Errors } from '../../../../Errors';
import { UserIdentityInput, useUserIdentityInput } from '../../../../UserIdentityInput';

interface RegistrationFormProps {
  onComplete: (user: User) => void;
}

const RegistrationForm = ({ onComplete }: RegistrationFormProps) => {
  const { submitButtonDisabled, errors, onSubmit, setValue, control, loading } = useRegistration(onComplete);
  const { nicknameStatus, onNicknameChange, onAliasChange, aliasStatus } = useUserIdentityInput({
    control,
    setValue,
  });

  return (
    <form onSubmit={onSubmit}>
      <UserIdentityInput
        blocked={false}
        nicknameStatus={nicknameStatus}
        aliasStatus={aliasStatus}
        control={control}
        onNicknameChange={onNicknameChange}
        onAliasChange={onAliasChange}
      />
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <TextInput className="border-b-0" {...field} placeholder="Почта" type="email" autoComplete="email" />
        )}
      />
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <TextInput {...field} placeholder="Пароль" type="password" autoComplete="current-password" />
        )}
      />
      <Errors errors={errors} />
      <Button
        className="mt-14 flex w-64 justify-start"
        type="submit"
        variant={ButtonVariant.Contained}
        disabled={submitButtonDisabled}
      >
        {loading ? 'Регистрируем...' : 'Регистрация'}
      </Button>
    </form>
  );
};

export { RegistrationForm };
