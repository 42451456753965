import { ToggleButton } from '../ToggleButton';
import { tw } from '../../styles/tw';

interface Props<T extends string> {
  buttons: ButtonParams<T>[];
  selectedValue: T;
  onClick: (value: T, popupUrl?: string) => void;
  className?: string;
}

interface ButtonParams<T extends string> {
  title: string;
  value: T;
  to?: string;
  popupUrl?: string;
}

const ButtonGroup = <T extends string>({ buttons, selectedValue, onClick, className }: Props<T>): JSX.Element => (
  <div className={tw('flex flex-wrap gap-1.5 p-0', className)}>
    {buttons.map(({ title, value, popupUrl, ...rest }) => (
      <ToggleButton key={title} active={selectedValue === value} onClick={() => onClick(value, popupUrl)} {...rest}>
        {title}
      </ToggleButton>
    ))}
  </div>
);

export { ButtonGroup };
