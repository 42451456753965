export enum AuthType {
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK',
  Vk = 'VK',
  Ok = 'OK',
  Login = 'LOGIN',
  Registration = 'REGISTRATION',
  PasswordReset = 'RESET',
}

export interface AuthFormProps {
  onClose: () => void;
  setAuthType: (authType: AuthType) => void;
}
