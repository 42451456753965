import { Controller } from 'react-hook-form';
import { TextInput } from '../../../../TextInput';
import { Button, ButtonVariant } from '../../../../Button';
import { useNewPassword } from './useNewPassword';
import { Errors } from '../../../../Errors';

interface PasswordResetFormProps {
  email: string;
  onDone: () => void;
}

const NewPasswordForm = ({ onDone, email }: PasswordResetFormProps) => {
  const { onSubmit, control, errors, submitButtonDisabled, loading } = useNewPassword(email, onDone);

  return (
    <form onSubmit={onSubmit}>
      <p className="mb-3.5">Введите новый пароль и код из почты</p>
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <TextInput
            className="[&:not(:last-of-type)]:border-b-0"
            {...field}
            type="password"
            placeholder="Новый пароль"
            autoComplete="new-password"
          />
        )}
      />
      <Controller
        control={control}
        name="code"
        render={({ field }) => (
          <TextInput
            className="[&:not(:last-of-type)]:border-b-0"
            {...field}
            placeholder="Код подтверждения"
            autoComplete="one-time-code"
          />
        )}
      />
      <Errors errors={errors} />
      <Button
        className="mt-14 flex w-64 justify-start"
        type="submit"
        variant={ButtonVariant.Contained}
        disabled={submitButtonDisabled}
      >
        {loading ? 'Отправляем...' : 'Готово'}
      </Button>
    </form>
  );
};

export { NewPasswordForm };
