import { Controller } from 'react-hook-form';
import { TextInput } from '../../../../TextInput';
import { Button, ButtonVariant } from '../../../../Button';
import { usePasswordReset } from './usePasswordReset';
import { Errors } from '../../../../Errors';

interface PasswordResetFormProps {
  setEmail: (email: string) => void;
}

const PasswordResetForm = ({ setEmail }: PasswordResetFormProps) => {
  const { onSubmit, control, errors, submitButtonDisabled, loading } = usePasswordReset(setEmail);

  return (
    <form onSubmit={onSubmit}>
      <p className="mb-3.5">Введите email</p>
      <Controller
        control={control}
        name="email"
        render={({ field }) => <TextInput {...field} placeholder="Почта" autoComplete="email" type="email" />}
      />
      <Errors errors={errors} />
      <Button
        className="mt-14 flex w-64 justify-start"
        type="submit"
        variant={ButtonVariant.Contained}
        disabled={submitButtonDisabled}
      >
        {loading ? 'Отправляем...' : 'Готово'}
      </Button>
    </form>
  );
};

export { PasswordResetForm };
