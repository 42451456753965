import * as yup from 'yup';

const MIN_PASSWORD_LENGTH = 8;

const schema = yup
  .object({
    nickname: yup.string().required('Необходимо ввести имя'),
    alias: yup.string().required('Необходимо ввести ссылку'),
    email: yup.string().email('Необходимо ввести Email'),
    password: yup
      .string()
      .required('Необходимо ввести пароль')
      .min(MIN_PASSWORD_LENGTH, `Пароль должен быть не короче ${MIN_PASSWORD_LENGTH} символов`),
  })
  .required();

export { schema };
