import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NewPasswordState } from './types';
import { ConfirmUserPasswordResetDocument } from './ConfirmUserPasswordReset.document';
import { ignoreReject } from '../../../../../lib/ignoreReject';

const schema = yup
  .object({
    password: yup.string().required('Необходимо ввести пароль'),
    code: yup.string().required('Необходимо ввести код'),
  })
  .required();

export const useNewPassword = (email: string, onDone: () => void) => {
  const [setPassword, { loading }] = useMutation(ConfirmUserPasswordResetDocument);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<NewPasswordState>({
    defaultValues: { password: '', code: '' },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const submitButtonDisabled = !isValid || loading;

  const onSubmit = handleSubmit(({ password, code }: NewPasswordState) => {
    void ignoreReject(
      setPassword({ variables: { password, email, code } }).then(() => {
        onDone();
      })
    );
  });

  return { control, errors, onSubmit, submitButtonDisabled, loading };
};
